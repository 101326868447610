import React, {useEffect, useState} from "react";
import {agentInfoApi, IAgentInfoRes} from "../../../services/api/agent_info";
import {getUserId, updateSessionInfo} from "../../../services/session";
import {Consumer, consumerApi} from "../../../services/api/consumer";
import i18n from "../../utils/i18n";
import {isAgentSettings, isBrokerPortal} from "../listing_management/utils";

interface IAgentUserProviderContext {
    agentInfo: IAgentInfoRes,
    consumerInfo: Consumer,
    agentId: number,
    countryCode: string,
    isMlsAgent: () => boolean,
    isFullAgent: () => boolean,
    isGatewayAgent: () => boolean,
    isLiteAgent: () => boolean,
    canAgentPublishListing: () => boolean,
    canAgentCreateOtherListing: () => boolean,
    canAgentCreateOwnListing: () => boolean
}

export const AgentUserContext = React.createContext<IAgentUserProviderContext>(null);

export function AgentUserProvider(props) {

    const [agentInfo, setAgentInfo] = useState(null);
    const [consumerInfo, setConsumerInfo] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [countryCode, setCountryCode] = useState('US');

    const initAgentInfo = async () => {
        const fromAgentSetting = window.location.pathname === '/agent/settings'
        const userId =  await getUserId();
        const agentInfo = await agentInfoApi.get(userId, true, null, fromAgentSetting);
        const localeSettings = agentInfo?.locale_settings
        const langLocale =  localeSettings?.language + "-"+  localeSettings?.country_code;

        setAgentInfo(agentInfo);
        setAgentId(parseInt(userId));
        setCountryCode(localeSettings?.country_code);
        await i18n.changeLanguage(langLocale)
    };

    const initConsumerInfo = async () => {
        const consumerInfo = await consumerApi.get();
        setConsumerInfo(consumerInfo);
    }

    // Rename isFullAgent to isProAgent and figure out a way to re-use utils/listings.ts
    const isFullAgent = () => {
        return ['pro', 'pro_search', 'pro_limited', 'pro_admin', 'pro_appraiser'].includes(agentInfo?.agent_type)
    };

    const isLiteAgent = () => {
        return agentInfo?.agent_type === 'lite';
    };

    const isGatewayAgent = () => {
        return ['gateway', 'gateway_search', 'gateway_admin', 'gateway_limited', 'gateway_appraiser'].includes(agentInfo?.agent_type)
    };

    const isMlsAgent = () => {
        return isGatewayAgent();
    };

    const canAgentPublishListing = () => {
        return agentInfo?.can_publish;
    }

    const canAgentCreateOtherListing = () => {
        return agentInfo?.can_create_others;
    }

    const canAgentCreateOwnListing = () => {
        return agentInfo?.can_create_own;
    }

    const handleSession = async () => {
        if (isBrokerPortal()) {
            await updateSessionInfo();
            window.addEventListener('session-update', initAgentInfo);
            initConsumerInfo();
        }
        if (isAgentSettings()) {
            initAgentInfo()
        }
    }

    useEffect(() => {
        handleSession();
    }, []);

    return (
        <AgentUserContext.Provider
            value={{agentInfo, agentId, consumerInfo, countryCode, isMlsAgent, isFullAgent, isLiteAgent, isGatewayAgent, canAgentPublishListing, canAgentCreateOtherListing, canAgentCreateOwnListing}}
        >
            {props.children}
        </AgentUserContext.Provider>
    )
}
