import {
    Button, Card, CardActions, CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import {requiredEmailValidation} from "../../../utils/validations";
import {userApi} from "../../../../services/rails_api/broker_portal/user";
import {emailUsernameNeeded, getUserId, updateSessionInfo} from "../../../../services/session";
import {agentInfoApi} from "../../../../services/api/agent_info";

const duplicateEmailValidationSchema = Yup.object().shape({
    user_name: requiredEmailValidation
});

export function DuplicateEmailDialog() {

    const [showDuplicateEmailModal, setShowDuplicateEmailModal] = useState(false);
    const [showPopOver, setShowPopOver] = useState(false);
    const [agentInfo, setAgentInfo] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [alertContent, setAlertContent] = useState("");

    const handleDialogClose = (event, reason) => {
        setShowDuplicateEmailModal(false);
    };

    const duplicateFormValues = {
        user_name: ''
    };
    const initAgentInfo = async () => {
        const userId =  await getUserId();
        const agentInfo = await agentInfoApi.get(userId);

        setAgentInfo(agentInfo);
        setAgentId(parseInt(userId));
    };

    const handleSubmitDuplicateEmail = async (values) => {
        const formValues = {...values, id: agentId}
        const response = await userApi.put(formValues)
        const code = response?.code;
        if(code >= 200 && code < 300) {
            setShowDuplicateEmailModal(false);
            sessionStorage.setItem("need_email_username", "false")
        }
        else {
            setAlertContent(response?.error?.message)
            setShowDuplicateEmailModal(true);
        }
    }

    const handlePopClose = () => {
        setShowPopOver(false);
    };

    const handleInfoClick = () => {
        setShowPopOver(true);
    };

    const emailNeeded = async () => {
        const res = await emailUsernameNeeded();
        setShowDuplicateEmailModal(res === 'true');
    }

    useEffect(() => {
        // updateSessionInfo();
        window.addEventListener('session-update', initAgentInfo);
        emailNeeded().then(r => r)
    }, [])

    return (
        <div>
            {
                showDuplicateEmailModal ?
                    <Dialog
                        open={showDuplicateEmailModal}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                handleDialogClose(event, reason);
                            }
                        }}
                        className="duplicate-email--container">
                        <DialogContent>
                            <DialogContentText>
                                Our records indicate that {agentInfo?.email} is associated with more than one {agentInfo?.brokerage_name} user accounts. To maintain user security and streamline your user experience please provide a unique email address.
                            </DialogContentText>
                            <Formik
                                initialValues={duplicateFormValues}
                                onSubmit={handleSubmitDuplicateEmail}
                                validationSchema={duplicateEmailValidationSchema}
                            >
                                {({handleSubmit}) => (
                                    <form onSubmit={handleSubmit}>
                                        <Form.Row className="center-align">
                                            <Form.Group as={Col} md="2">
                                                <label htmlFor="user_name">Email Address:</label>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Field autoFocus type="text" name="user_name" className="form-control"/>
                                                <ErrorMessage className="error-message" name="user_name" component="div"/>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className=""
                                                    type="submit"
                                                >
                                                    Update
                                                </Button>
                                            </Form.Group>
                                        </Form.Row>
                                    </form>
                                )}
                            </Formik>
                            <DialogContentText>
                                <div className="error-message">
                                    {
                                        alertContent
                                    }
                                </div>
                                <Button href="#text-buttons" className="link-text" onClick={handleInfoClick}>Why am I being asked for a different email address?</Button>
                                {
                                    showPopOver ?
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography>
                                                    Rental Beast is a technology firm providing rental-centric software tools in partnership with {agentInfo?.brokerage_name}. To maintain your security and safeguard user and client information, each Rental Beast user account is associated with a unique email address. Designating a unique email address also assures the proper delivery of lead notifications, client alerts, and other transactional communications. Rental Beast will not share your unique email address with external partners, and specifying a unique email address will not update or alter your email settings with {agentInfo?.brokerage_name}.
                                                </Typography>
                                            </CardContent>
                                            <CardActions className="close-card">
                                                <Button variant="contained" color="primary" size="small" onClick={handlePopClose}>
                                                    Close
                                                </Button>
                                            </CardActions>
                                        </Card> :
                                        null
                                }
                            </DialogContentText>
                        </DialogContent>
                    </Dialog> : null
            }
        </div>
    )
}
