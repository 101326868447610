import React from 'react'
import ReactOnRails from "react-on-rails";
import './upgrade.scss';
import { useTranslation } from "react-i18next";
import {AgentUserProvider} from "../../../src/features/broker_search/agent_user_context";
import {SweetAlertsProvider} from "../../../src/components/sweet_alert/sweet_alert";
import {BrowserRouter as Router, Route} from "react-router-dom";


export function Upgrade ({ customClass }): any {
    const { t } = useTranslation( 'translation',{useSuspense: false});

    const demoAndProUpgradeButtons = () => (
        <>
            <button
                className='upgrade-pro-btn'
                onClick={() => window.open('/agent/subscriptions_and_payment', '_blank')}
            >
                UPGRADE TO PRO
            </button>
            <span>or</span>
            <button
                className='upgrade-demo-btn'
                onClick={() => window.open('https://go.rentalbeast.com/rbpdemorequest.html', '_blank')}
            >
                GET A DEMO
            </button>
        </>
    )

    return (
        <>
            <div 
                className={`${customClass} upgrade-content`}
                style={
                    window.location.pathname.includes('upgrade')
                      ? { margin: 'auto', marginTop: '40px' }
                      : {}
                  }
            >
                <span className='upgrade-txt'><b>Looking for more?</b> Upgrade to Rental Beast Pro for full access to thousands of exclusive owner-sourced listings and additional tools, including:</span>
                <div className='upgrade-access-features'>
                    <div className='access-features'>
                        <li><span>&#10003;</span><b>Priority leads</b> exclusive to Pro subscribers</li>
                        <li><span>&#10003;</span>A <b>customizable rental website</b> to showcase your brand</li>
                        <li><span>&#10003;</span><b>Premium syndication</b> tools to maximize exposure</li>
                    </div>
                </div>
            </div>
            <div
                className={`${customClass} upgrade-content`}
                style={{
                    ...(window.location.pathname.includes('upgrade') && {
                        margin: 'auto',
                    }),
                    marginBottom: 15,
                }}
            >
                <span className='upgrade-txt'><b>Ready to take control of your business?</b></span>
            </div>
            <div className='upgrade-buttons'>{demoAndProUpgradeButtons()}</div>
            <div    className={`${customClass} upgrade-content`}
                style={{
                    marginBottom: 15,
                    ...(window.location.pathname.includes('upgrade') && {
                        margin: 'auto',
                    }),
            }}>
                <span className='upgrade-txt' style={{fontSize: 18}}>*Note: Pro benefits may vary based on location and available services</span>
            </div>
            <div className='rbg-vs-rbp-chart-wrapper'>
                <div className="w-layout-grid grid-20 snipcss-dkCvR">
                    <div className="text-block-rbg rows-grid">
                     
                    </div>
                    <div className="text-block-rbg rows-grid" style={{display: 'flex', alignItems: 'center'}}>
                        Rental Beast Gateway
                    </div>
                    <div className="text-block-rbg rows-grid" style={{display: 'flex', alignItems: 'center'}}>
                       vs.  Rental Beast Pro (Paid Upgrade)
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                            Owner Sourced Rental Listings
                        </strong>
                    </div>
                    <div className="text-block-rbp rows-grid" style={{color: '#20456f'}}>
                        Preview Listing Only
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                       <span style={{fontSize: 20, marginRight: 10, color: '#1dd117'}}>&#10004;</span> Full Listing Access
                    </div>
                    <div className="text-block-lg-feature rows-grid" style={{background: 'white'}}>
                        <strong className='strong-texts'>
                            Rental Leads
                        </strong>
                    </div>
                    <div className="text-block-rbp rows-grid" style={{color: '#20456f', background: 'white'}}>
                        Your listing, Your lead
                    </div>
                    <div className="text-block-rbp-dg rows-grid" style={{background: 'white', alignItems: 'flex-start'}}>
                        <span style={{fontSize: 20, marginRight: 10, color: '#1dd117'}}>&#10004;</span> Your listing, your lead PLUS additional leads generated from Rental Beast listings
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        <strong className='strong-texts'>
                            Rental Website
                        </strong>
                    </div>
                    <div className="text-block-lg-feature rows-grid">
                        {""}
                    </div>
                    <div className="text-block-rbp-dg rows-grid">
                        <span style={{fontSize: 20, marginRight: 10, color: '#1dd117'}}>&#10004;</span> Your own custom rental website
                    </div>
                </div>
            </div>
        </>
    )
}

export function UpgradeUser() {
    return (
        <AgentUserProvider>
            <SweetAlertsProvider>
                <Router>
                    <Route exact path="/broker_portal/upgrade">
                        <Upgrade customClass='upgrade-content-ldp' />
                    </Route>
                </Router>
            </SweetAlertsProvider>
        </AgentUserProvider>
    );
}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    UpgradeUser
});
